@import url(https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Indie+Flower&display=swap);
html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
}
body * {
  font-family: 'Indie Flower', cursive;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.text-uppercase {
  text-transform: uppercase;
}
.font-weight-bold {
  font-weight: bold;
}

.mt-1 {
  margin-top: 4px !important;
}
.mt-2 {
  margin-top: 8px !important;
}
.mt-3 {
  margin-top: 12px !important;
}
.mt-4 {
  margin-top: 16px !important;
}
.mt-5 {
  margin-top: 20px !important;
}
.mt-6 {
  margin-top: 24px !important;
}
.pt-1 {
  padding-top: 4px !important;
}
.pt-2 {
  padding-top: 8px !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.ps-1 {
  padding-left: 4px;
}

.px-1 {
  padding-left: 4px;
  padding-right: 4px;
}
.px-2 {
  padding-left: 8px;
  padding-right: 8px;
}
.mx-1 {
  margin-left: 2px;
  margin-right: 2px;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.font-family-monospaced,
.font-family-monospaced * {
  font-family: Courier New, monospace !important;
}

.code-digit {
  border-bottom: 1px solid;
}

.flex {
  flex: 1 1;
}
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row !important;
}

.d-inline-flex {
  display: inline-flex;
}
.d-inline-block {
  display: inline-block;
}

.w-100 {
  width: 100%;
}
.justify-center {
  justify-content: center !important;
}
.justify-space-around {
  justify-content: space-around;
}
.align-center {
  align-items: center;
}

.card {
  margin: 0 5px;
  border-radius: 4px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  border-width: thin;
  display: block;
  max-width: 100%;
  outline: none;
  text-decoration: none;
  transition-property: box-shadow, opacity;
  overflow-wrap: break-word;
  position: relative;
  white-space: normal;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.card.dark {
  background: #112aa7;
  color: rgba(255, 255, 255, 0.87);
}
.card-text {
  padding: 16px;
}
.text--white {
  color: white;
}
.text--orange {
  color: orange;
}
.text--grey {
  color: grey;
}
.text--green {
  color: #00a700;
}
.text--black {
  color: #000000;
}
.muted {
  opacity: 0.7;
}
.text-h3 {
  font-size: 1.6em;
}
.text-h4 {
  font-size: 1.3em;
}
.text-center {
  text-align: center;
}
.text-underline {
  text-decoration: underline;
}
.overflow-auto {
  overflow: auto;
}

.position-relative {
  position: relative;
}

.avatar {
  overflow: hidden;
  max-width: 70px;
  padding: 0 15px;
}

.avatar img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  border: 3px solid;
}

.leader .avatar img {
  border-color: orange;
}

.activePlayer {
  color: #0000ad;
}
table {
  font-weight: 300;
  min-width: 1000px;
}
table td,
table th {
  position: relative;
  padding: 0 10px;
}
.table-tip {
  position: absolute;
  font-size: 0.35em;
  line-height: 1.25em;
  background: orange;
  top: 0;
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  padding: 0 3px;
  z-index: 2;
}

.leaderboard--listing .activePlayer {
  background-color: lightgoldenrodyellow;
  padding: 5px 0;
}

.banner_root {
  background-size: contain;
  background-repeat: no-repeat;
  font-weight: bold;
  display: flex;
  justify-content: center;
  color: white;
  transition: 0.3s -webkit-transform;
  transition: 0.3s transform;
  transition: 0.3s transform, 0.3s -webkit-transform;
  -webkit-animation: banner_enter-anim 1s;
          animation: banner_enter-anim 1s;
}

.banner_root-md {
  margin-top: calc(1vh * 2);
  margin-top: calc(var(--vh, 1vh) * 2);
  width: calc(1vh * 56.5);
  width: calc(var(--vh, 1vh) * 56.5);
  height: calc(1vh * 11);
  height: calc(var(--vh, 1vh) * 11);
  font-size: calc(1vh * 3.5);
  font-size: calc(var(--vh, 1vh) * 3.5);
}

.banner_root-sm {
  margin-top: calc(1vh * 0);
  margin-top: calc(var(--vh, 1vh) * 0);
  width: calc(1vh * 48.5);
  width: calc(var(--vh, 1vh) * 48.5);
  height: calc(1vh * 9);
  height: calc(var(--vh, 1vh) * 9);
  font-size: calc(1vh * 3.5);
  font-size: calc(var(--vh, 1vh) * 3.5);
}

@-webkit-keyframes banner_enter-anim {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes banner_enter-anim {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.banner_partial {
  -webkit-transform: translateX(-30%);
          transform: translateX(-30%);
}

.banner_partial-text {
  -webkit-transform: translateX(60%);
          transform: translateX(60%);
}

.banner_body {
  display: flex;
  align-items: center;
  height: 93%;
}

.title-banner_root {
  color: white;
  margin-top: calc(1vh * 2);
  margin-top: calc(var(--vh, 1vh) * 2);
  width: 100%;
  height: calc(1vh * 10);
  height: calc(var(--vh, 1vh) * 10);
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  font-weight: bold;
  font-size: calc(1vh * 3.5);
  font-size: calc(var(--vh, 1vh) * 3.5);
}

.title-banner_line {
  width: 100%;
  height: calc(1vh * 1);
  height: calc(var(--vh, 1vh) * 1);
  position: absolute;
  left: 0;
}

.title-banner_line-top {
  top: calc(1vh * 1);
  top: calc(var(--vh, 1vh) * 1);
}

.title-banner_line-bottom {
  bottom: calc(1vh * 1);
  bottom: calc(var(--vh, 1vh) * 1);
}

