.banner_root {
  background-size: contain;
  background-repeat: no-repeat;
  font-weight: bold;
  display: flex;
  justify-content: center;
  color: white;
  transition: 0.3s transform;
  animation: banner_enter-anim 1s;
}

.banner_root-md {
  margin-top: calc(var(--vh, 1vh) * 2);
  width: calc(var(--vh, 1vh) * 56.5);
  height: calc(var(--vh, 1vh) * 11);
  font-size: calc(var(--vh, 1vh) * 3.5);
}

.banner_root-sm {
  margin-top: calc(var(--vh, 1vh) * 0);
  width: calc(var(--vh, 1vh) * 48.5);
  height: calc(var(--vh, 1vh) * 9);
  font-size: calc(var(--vh, 1vh) * 3.5);
}

@keyframes banner_enter-anim {
  0% {
    transform: translateX(-100%);
  }
}

.banner_partial {
  transform: translateX(-30%);
}

.banner_partial-text {
  transform: translateX(60%);
}

.banner_body {
  display: flex;
  align-items: center;
  height: 93%;
}

.title-banner_root {
  color: white;
  margin-top: calc(var(--vh, 1vh) * 2);
  width: 100%;
  height: calc(var(--vh, 1vh) * 10);
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  font-weight: bold;
  font-size: calc(var(--vh, 1vh) * 3.5);
}

.title-banner_line {
  width: 100%;
  height: calc(var(--vh, 1vh) * 1);
  position: absolute;
  left: 0;
}

.title-banner_line-top {
  top: calc(var(--vh, 1vh) * 1);
}

.title-banner_line-bottom {
  bottom: calc(var(--vh, 1vh) * 1);
}
